import Vue from 'vue'
import App from './App.vue'
import router from './router/index2'
import store from './store'
import network from './network'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 导入微信js-sdk
import wx from "jweixin-module";
// 挂载到Vue原型上
Vue.prototype.$wx=wx;
Vue.prototype.$network = network;

Vue.use(ElementUI);

Vue.config.productionTip = false


router.beforeEach((to,from,next) =>{
  if(to.meta.title){
    document.title = to.meta.title
  }
  next();
})

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
