import Vue from 'vue'
import VueRouter from 'vue-router'
import Museum from '../views/Museum.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Museum',
    component: Museum,
    meta: {
      // 页面标题title
      title: '幸福声音博物馆'
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
